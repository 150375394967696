<template>
  <section class="bg-gray-100 py-8">
    <div class="container max-w-5xl mx-auto">
      <h2 class="text-3xl text-gray-800 font-bold mb-4 text-center">Neem contact met mij op</h2>
      <p class="text-center text-gray-600 mb-8">Heb je vragen of wil je samenwerken? Laat een bericht achter!</p>
      <form @submit.prevent="submitForm" class="max-w-lg mx-auto">
        <input v-model="formData.name" class="w-full p-2 mb-4 border border-gray-300 rounded text-gray-800" type="text" placeholder="Naam" required>
        <input v-model="formData.email" class="w-full p-2 mb-4 border border-gray-300 rounded text-gray-800" type="email" placeholder="E-mail" required>
        <textarea v-model="formData.message" class="w-full p-2 mb-4 border border-gray-300 rounded text-gray-800" placeholder="Bericht" required></textarea>
        <button type="submit" class="bg-purple-700 text-white py-2 px-4 rounded-full hover:bg-purple-500">Verstuur</button>
      </form>
      <p v-if="submissionSuccess" class="text-green-600 mt-4 text-center">Bedankt voor je bericht! We nemen spoedig contact met je op.</p>
    </div>
  </section>
</template>

<script>
import emailjs from 'emailjs-com';

export default {
  name: 'ContactComponent',
  data() {
    return {
      formData: {
        name: '',
        email: '',
        message: ''
      },
      submissionSuccess: false
    };
  },
  methods: {
    submitForm() {
      const serviceID = 'service_iqmyc29';
      const templateID = 'template_7zx4bnf';
      const userID = 'pkkQg2vi504RuBvNK';

      const templateParams = {
        from_name: this.formData.name,
        from_email: this.formData.email,
        message: this.formData.message
      };

      emailjs.send(serviceID, templateID, templateParams, userID)
          .then(response => {
            console.log('Succes:', response.status, response.text);
            this.submissionSuccess = true;
            this.formData.name = '';
            this.formData.email = '';
            this.formData.message = '';
          })
          .catch(error => {
            console.error('Fout bij verzenden:', error);
            alert('Er is iets misgegaan. Probeer het later opnieuw.');
          });
    }
  }
};
</script>

<style scoped>
.scene-container {
  width: 400px;
  height: 300px;
  display: block;
}
</style>