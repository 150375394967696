<template>
  <!-- Header -->
  <HeaderComponent />

  <!-- About Section -->
  <section class="bg-white text-gray-800 py-16">
    <div class="container mx-auto flex flex-col items-center text-center">
      <h1 class="text-4xl lg:text-5xl font-bold mb-4">Over Mij</h1>
      <p class="text-lg lg:text-xl mb-8">
        Hallo! Ik ben Lianne Strik, een softwareontwikkelaar met een passie voor technologie, 3D-printen, en home automation.
        In mijn vrije tijd werk ik aan creatieve projecten zoals 3D-geprinte objecten, stickers maken, en het automatiseren van mijn slimme huis met een combinatie van Home Assistant en Homey.
      </p>
    </div>
  </section>

  <!-- Showcase Section -->
  <section class="bg-white py-16">
    <div class="container max-w-5xl mx-auto">
      <h2 class="text-3xl lg:text-5xl font-bold text-gray-800 text-center mb-12">Mijn hobbies</h2>

      <!-- 3D Geprinte Objecten -->
      <div class="mb-12">
        <h3 class="text-2xl lg:text-3xl font-bold text-gray-800 mb-6">3D Geprinte Objecten</h3>
        <div class="grid grid-cols-1 md:grid-cols-4 gap-8">
          <img src="/img/3dprint_1.jpg" alt="3D geprint object 1" class="rounded-lg shadow-lg hover:scale-105 transition transform duration-300 ease-in-out">
          <img src="/img/3dprint_2.jpg" alt="3D geprint object 2" class="rounded-lg shadow-lg hover:scale-105 transition transform duration-300 ease-in-out">
          <img src="/img/catan_1.jpg" alt="3D geprint object 3" class="rounded-lg shadow-lg hover:scale-105 transition transform duration-300 ease-in-out">
          <img src="/img/catan_2.jpg" alt="3D geprint object 3" class="rounded-lg shadow-lg hover:scale-105 transition transform duration-300 ease-in-out">
        </div>
        <div class="grid grid-cols-1 md:grid-cols-3 gap-8 mt-8">
        <ThreeComponent class="rounded-lg shadow-lg hover:scale-105 transition transform duration-300 ease-in-out" :object="'/img/kaarthouder.stl'" :scale="{ x: 0.09, y: 0.09, z: 0.09 }" :position="{ x: 0, y: -1, z: 0 }"/>
        <ThreeComponent class="rounded-lg shadow-lg hover:scale-105 transition transform duration-300 ease-in-out" :object="'/img/arduino.stl'" :scale="{ x: 0.09, y: 0.09, z: 0.09 }" :position="{ x: 0.5, y: -1.5, z: 0 }"/>
        <ThreeComponent class="rounded-lg shadow-lg hover:scale-105 transition transform duration-300 ease-in-out" :object="'/img/boekel_kerk.stl'" :scale="{ x: 0.08, y: 0.08, z: 0.08 }" :position="{ x: 4, y: -2.5, z: 0 }"/>
        </div>
      </div>

      <!-- Stickers met de Plotter -->
      <div class="mb-12">
        <h3 class="text-2xl lg:text-3xl font-bold text-gray-800 mb-6">Stickers</h3>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
          <img src="/img/stickers.jpg" alt="Sticker ontwerp 1" class="rounded-lg shadow-lg hover:scale-105 transition transform duration-300 ease-in-out">
        </div>
      </div>

      <!-- Home Assistant Dashboard -->
      <div class="mb-12">
        <h3 class="text-2xl lg:text-3xl font-bold text-gray-800 mb-6">Home Assistant Dashboard</h3>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
          <img src="/img/home_assistant.jpg" alt="Home Assistant Dashboard 1" class="rounded-lg shadow-lg hover:scale-105 transition transform duration-300 ease-in-out">
        </div>
      </div>
    </div>
  </section>

  <!-- Contact Section -->
  <Contact />

  <!-- Footer -->
  <Footer />
</template>

<script>
import Footer from '@/components/Footer.vue';
import Contact from "@/components/Contact";
import HeaderComponent from "@/components/Header";
import ThreeComponent from "@/components/3dcomponent.vue";

export default {
  name: 'AboutPage',
  components: {
    HeaderComponent,
    Footer,
    Contact,
    ThreeComponent
  }
}
</script>

<style scoped>
/* Hero Section Styling */
.section-hero {
  background-color: #1A202C;
  color: white;
}

img {
  width: 100%;
  height: 300px; /* Stel de gewenste hoogte in */
  object-fit: cover; /* Houd de verhoudingen van de afbeelding intact, en vul het frame */
  border-radius: 8px; /* Geef de afbeeldingen afgeronde hoeken voor een mooie look */
}

.button:hover {
  background-color: #6B46C1;
}

.text-center {
  text-align: center;
}

/* Image Hover Effects */
img:hover {
  transform: scale(1.05);
}

/* General Styling */
h2 {
  color: #333;
  font-weight: 700;
}

h3 {
  color: #333;
  font-weight: 600;
}

p {
  color: #666;
}
</style>
