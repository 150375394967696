<template>
  <footer class="bg-purple-900 text-white py-6">
    <div class="container mx-auto text-center">
      <p class="text-white">&copy; 2024 Lianne Strik. Alle rechten voorbehouden.</p>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'FooterComponent'
}
</script>