import { createWebHistory, createRouter } from "vue-router";
import Home from "@/pages/Home.vue";
import About from "@/pages/About.vue";
import Projects from "@/pages/Projects.vue";

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: "/about",
        name: "About",
        component: About,
    },
    {
        path: "/projects",
        name: "Projects",
        component: Projects,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
