<template>
  <div>
    <!-- Subtiele animatie met SVG-golven -->
    <svg class="waves" xmlns="http://www.w3.org/2000/svg" viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
      <defs>
        <path id="gentle-wave"
              d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"/>
      </defs>
      <g class="parallax">
        <use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(72, 61, 139, 0.7)"/>
        <use xlink:href="#gentle-wave" x="48" y="3" fill="rgba(72, 61, 139, 0.5)"/>
        <use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(72, 61, 139, 0.3)"/>
        <use xlink:href="#gentle-wave" x="48" y="7" fill="#483D8B"/>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'HeaderComponent',
  data() {
    return {
      scrollPosition: 0,
    };
  },
  mounted() {
    window.addEventListener('scroll', this.updateScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.updateScroll);
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
    }
  }
};
</script>

<style scoped>
/* SVG Golven */
.waves {
  position: relative;
  width: 100%;
  height: 10vh;
  min-height: 80px;
  max-height: 150px;
}
.parallax > use {
  animation: move-forever 35s cubic-bezier(.55,.5,.45,.5) infinite;
}
.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 15s; /* Verhoogd voor langzamere beweging */
}
.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 20s; /* Verhoogd voor langzamere beweging */
}
.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 25s; /* Verhoogd voor langzamere beweging */
}
.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 30s; /* Verhoogd voor langzamere beweging */
}

@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }
  100% {
    transform: translate3d(85px, 0, 0);
  }
}

/* Menu styling */
#header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 50;
  transition: background-color 0.3s ease;
}
.menu-link {
  color: white;
  text-decoration: none;
  font-weight: 600;
  padding: 0.5rem 1rem;
  transition: color 0.3s ease;
}
.menu-link:hover {
  color: #BB86FC; /* Donkerpaars accent */
}
</style>
