<template>
  <!-- Header -->
  <HeaderComponent />

  <!-- Hero Section -->
  <section class="bg-white text-gray-800 py-16">
    <div class="container mx-auto flex flex-col items-center text-center">
      <p class="text-lg lg:text-xl mb-6">Ik ben Lianne Strik, softwareontwikkelaar met een passie voor Home Automation en Laravel-projecten.</p>
      <router-link to="/projects" class="button bg-purple-600 text-white py-2 px-6 rounded-full hover:bg-purple-500 transition transform hover:scale-105">
        Bekijk mijn Projecten
      </router-link>
    </div>
  </section>

  <!-- Info Section -->
  <section class="bg-white border-b py-16">
    <div class="container max-w-5xl mx-auto text-center">
      <h2 class="text-3xl lg:text-5xl font-bold text-gray-800 mb-8">Wat ik doe</h2>
      <p class="text-lg text-gray-600 leading-relaxed mb-8">
        Ik werk als softwareontwikkelaar in Uden en heb mijn eigen (hobby) Laravel-projecten lopen, waaronder een werkbonnenapplicatie. Daarnaast vind ik home automation erg interessant, en werk ik graag met technologieën zoals Home Assistant en Homey.
      </p>

      <!-- Icons Row -->
      <div class="flex flex-wrap justify-center space-x-8">
        <div class="w-1/3 text-center">
          <i class="fas fa-code text-6xl text-purple-600"></i>
          <h3 class="text-xl font-bold mt-4">Software Ontwikkeling</h3>
          <p class="text-gray-600 mt-2">Van concept tot uitvoering, ik bouw robuuste en schaalbare applicaties.</p>
        </div>
        <div class="w-1/3 text-center">
          <i class="fas fa-cogs text-6xl text-purple-600"></i>
          <h3 class="text-xl font-bold mt-4">Automatisering</h3>
          <p class="text-gray-600 mt-2">Slimme oplossingen voor thuis en kantoor met Home Assistant en Homey.</p>
        </div>
        <div class="w-1/3 text-center">
          <i class="fas fa-project-diagram text-6xl text-purple-600"></i>
          <h3 class="text-xl font-bold mt-4">Project Management</h3>
          <p class="text-gray-600 mt-2">Efficiënte en gestructureerde aanpak voor het beheren van projecten.</p>
        </div>
      </div>
    </div>
  </section>

  <!-- Contact Section -->
  <Contact />

  <!-- Footer -->
  <Footer />
</template>

<script>
import Footer from '@/components/Footer.vue';
import Contact from "@/components/Contact";
import HeaderComponent from "@/components/Header";

export default {
  name: 'HomePage',
  components: {
    HeaderComponent,
    Footer,
    Contact
  }
}
</script>

<style scoped>
/* Hero Section Styling */
.section-hero {
  background-color: #1A202C;
  color: white;
}

.button:hover {
  background-color: #6B46C1;
}

.text-center {
  text-align: center;
}

/* Icon Styling */
i {
  transition: color 0.3s ease;
}

i:hover {
  color: #BB86FC; /* Paars hover effect */
}

/* General Styling */
h2 {
  color: #333;
  font-weight: 700;
}

h3 {
  color: #333;
  font-weight: 600;
}

p {
  color: #666;
}
</style>
