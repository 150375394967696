<template>
  <!-- Header -->
  <HeaderComponent />

  <!-- Projecten Section -->
  <section class="bg-white text-gray-800 py-16">
    <div class="container mx-auto flex flex-col items-center text-center">
      <h1 class="text-4xl lg:text-5xl font-bold mb-4">Mijn Projecten</h1>
      <p class="text-lg lg:text-xl mb-8">
        Hier zijn enkele van mijn belangrijkste projecten. Elk project weerspiegelt mijn passie voor softwareontwikkeling en mijn creatieve oplossingen voor dagelijkse problemen.
      </p>
    </div>
  </section>

  <!-- Projecten Grid -->
  <section class="bg-white py-16">
    <div class="container max-w-5xl mx-auto">
      <h2 class="text-3xl lg:text-5xl font-bold text-gray-800 text-center mb-12">Uitgelichte Projecten</h2>
      <!-- Werkbonnen Applicatie -->
      <div class="mb-12">
        <h3 class="text-2xl lg:text-3xl font-bold text-gray-800 mb-4">Werkbonnen Applicatie</h3>
        <p class="text-lg text-gray-600 leading-relaxed mb-6">
          Deze applicatie is ontwikkeld voor bedrijven om gemakkelijk werkbonnen te creëren en te beheren. Werkbonnen kunnen per week worden geëxporteerd naar een PDF-bestand voor eenvoudige rapportage.
        </p>
        <img src="/img/strikwerkbonnen.png" alt="Werkbonnen Applicatie" class="rounded-lg shadow-lg mb-6 hover:scale-105 transition transform duration-300 ease-in-out">
<!--        <router-link to="/workorders" class="button bg-purple-600 text-white py-2 px-6 rounded-full hover:bg-purple-500 transition transform hover:scale-105">-->
<!--          Bekijk Werkbonnen Applicatie-->
<!--        </router-link>-->
      </div>

      <!-- LP Verzameling Applicatie -->
      <div class="mb-12">
        <h3 class="text-2xl lg:text-3xl font-bold text-gray-800 mb-4">LP Verzameling Applicatie</h3>
        <p class="text-lg text-gray-600 leading-relaxed mb-6">
          Deze applicatie is ontworpen voor LP-verzamelaars. Gebruikers kunnen hun verzameling bijhouden door een Excel-bestand te importeren en LP's toe te voegen of te verwijderen. Ideaal voor muziekliefhebbers die hun collectie digitaal willen beheren.
        </p>
        <img src="/img/vhoutlps.png" alt="LP Verzameling Applicatie" class="rounded-lg shadow-lg mb-6 hover:scale-105 transition transform duration-300 ease-in-out">
<!--        <router-link to="/lp-collection" class="button bg-purple-600 text-white py-2 px-6 rounded-full hover:bg-purple-500 transition transform hover:scale-105">-->
<!--          Bekijk LP Verzameling Applicatie-->
<!--        </router-link>-->
      </div>

      <p class="text-lg text-gray-600 leading-relaxed mb-6">
        Mijn applicatie is flexibel en past zich aan de behoeften van verschillende gebruikers aan. Dankzij de multi-client architectuur ondersteunt de applicatie zowel werkbonnenbeheer als lp-collectiebeheer, afgestemd op de specifieke wensen van elke client. <br/><br/>
        Voor werkbonnenbeheer biedt de applicatie functies voor het aanmaken, bijhouden en archiveren van werkbonnen, inclusief het vastleggen van werkdetails, gebruikersinformatie, en de mogelijkheid om werkbonnen per week te exporteren naar PDF.<br/><br/>
        Muziekliefhebbers kunnen hun lp-collectie eenvoudig beheren door lp's te importeren vanuit Excel, informatie toe te voegen of te bewerken, en hun collectie te doorzoeken of filteren.<br/><br/>
        Deze veelzijdigheid maakt mijn applicatie breed inzetbaar, waardoor gebruikers efficiënt hun gegevens kunnen beheren, of het nu gaat om werkbonnen of lp-collecties. De multi-client benadering biedt maatwerk zonder extra ontwikkelkosten, wat zorgt voor een oplossing die naadloos aansluit op hun behoeften.<br/><br/>
      </p>
    </div>
  </section>

  <!-- Contact Section -->
  <Contact />

  <!-- Footer -->
  <Footer />
</template>

<script>
import Footer from '@/components/Footer.vue';
import Contact from "@/components/Contact";
import HeaderComponent from "@/components/Header";

export default {
  name: 'ProjectsPage',
  components: {
    HeaderComponent,
    Footer,
    Contact
  }
}
</script>

<style scoped>
/* General Styling */
.section-hero {
  background-color: #1A202C;
  color: white;
}

.text-center {
  text-align: center;
}

/* Project Hover Effects */
img:hover {
  transform: scale(1.05);
}

.button:hover {
  background-color: #6B46C1;
}
</style>
