<template>
  <nav id="header" class="fixed w-full z-30 top-0 transition-all duration-300"
       :class="scrollPosition > 10 ? 'bg-gray-900 shadow-lg' : 'bg-transparent'">
    <div class="w-full container mx-auto flex flex-wrap items-center justify-between py-2">
      <!-- Logo -->
      <div class="pl-4 flex items-center">
        <a class="text-white no-underline hover:no-underline font-bold text-2xl lg:text-3xl" href="#">
          Lianne Strik
        </a>
      </div>

      <!-- Hamburger Menu Button for Mobile -->
      <div class="block lg:hidden pr-4">
        <button @click="toggleMenu"
                class="flex items-center p-1 text-white hover:text-purple-300 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
          <svg class="fill-current h-6 w-6" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/>
          </svg>
        </button>
      </div>

      <!-- Menu Links -->
      <div ref="nav-content" class="w-full lg:flex lg:items-center lg:w-auto mt-2 lg:mt-0 text-white p-4 lg:p-0 z-20"
           :class="{'hidden': hideMenu, 'block': !hideMenu}">
        <ul class="list-reset lg:flex justify-end flex-1 items-center">
          <li class="mr-3">
            <router-link to="/" class="menu-link">Home</router-link>
          </li>
          <li class="mr-3">
            <router-link to="/about" class="menu-link">About</router-link>
          </li>
          <li class="mr-3">
            <router-link to="/projects" class="menu-link">Projects</router-link>
          </li>
        </ul>
      </div>
    </div>
    <hr class="border-b border-gray-800 opacity-25 my-0 py-0"/>
  </nav>
</template>

<script>
export default {
  name: 'MenuComponent',
  data() {
    return {
      scrollPosition: 0,
      hideMenu: true,
    };
  },
  mounted() {
    window.addEventListener('scroll', this.updateScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.updateScroll);
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    toggleMenu() {
      this.hideMenu = !this.hideMenu;
    }
  }
}
</script>

<!-- Menu CSS -->
<style scoped>
/* Menu link styles */
.menu-link {
  color: white;
  text-decoration: none;
  font-weight: 600;
  padding: 0.5rem 1rem;
  transition: color 0.3s ease;
}
.menu-link:hover {
  color: #BB86FC; /* Donkerpaars accent */
}

/* Styling voor nav-bar */
#header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 50;
  transition: background-color 0.3s ease;
}

/* Hamburger Menu icon hover */
button svg:hover {
  fill: #BB86FC; /* Paars hover effect */
}
</style>
