<template>
  <div ref="sceneContainer" class="scene-container"></div>
</template>

<script>
import * as THREE from 'three';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';

export default {
  name: 'ThreeDPrinter',
  mounted() {
    this.initThree(this.scale, this.position);
  },
  props: {
    object: {
      type: String,
      default: ''
    },
    scale: {
      type: Object,
      default: () => ({ x: 0.09, y: 0.09, z: 0.09 })
    },
    position: {
      type: Object,
      default: () => ({ x: 0.5, y: -2.5, z: 0 })
    }
  },
  methods: {
    initThree(scale, position) {
      // Scene, camera, renderer setup
      const scene = new THREE.Scene();
      scene.background = new THREE.Color(0xffffff); // Zet de achtergrondkleur naar wit
      const camera = new THREE.PerspectiveCamera(75, this.$refs.sceneContainer.clientWidth / this.$refs.sceneContainer.clientHeight, 0.1, 1000);
      const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
      renderer.setSize(this.$refs.sceneContainer.clientWidth, this.$refs.sceneContainer.clientHeight);
      renderer.shadowMap.enabled = true; // Schaduwen inschakelen
      this.$refs.sceneContainer.appendChild(renderer.domElement);

      // STL Loader
      const loader = new STLLoader();
      loader.load(this.object, function (geometry) {
        const material = new THREE.MeshPhongMaterial({ color: 0xAAAAAA, shininess: 50 }); // Gebruik Phong materiaal voor betere details

        const mesh = new THREE.Mesh(geometry, material);
        mesh.rotation.x = -Math.PI / 2;
        mesh.position.set(position.x, position.y, position.z); // Verplaats de mesh naar een andere positie
        mesh.scale.set(scale.x, scale.y, scale.z); // Schaal de mesh naar een kleiner formaat
        mesh.castShadow = true;
        mesh.receiveShadow = true;

        scene.add(mesh);
      });

      // Lichtbron toevoegen voor betere verlichting
      const ambientLight = new THREE.AmbientLight(0x888888, 0.5); // Verlaag de intensiteit van het omgevingslicht
      scene.add(ambientLight);

      const pointLight = new THREE.PointLight(0xFFFFFF, 1.5); // Verhoog de intensiteit van het puntlicht
      pointLight.position.set(5, 5, 5);
      pointLight.castShadow = true; // Schaduw inschakelen voor puntlicht
      scene.add(pointLight);

      const directionalLight = new THREE.DirectionalLight(0xffffff, 1); // Richtlicht toevoegen voor meer contrast
      directionalLight.position.set(-5, 10, 5);
      directionalLight.castShadow = true;
      scene.add(directionalLight);

      // Camera positioneren
      camera.position.z = 12;
      camera.position.y = 5;
      camera.lookAt(0, 0, 0); camera.position.x = 1;

      // Animatielus
      function animate() {
        requestAnimationFrame(animate);
        scene.rotation.y += 0.005; // Laat de hele printer langzaam ronddraaien voor een beter zicht
        renderer.render(scene, camera);
      }
      animate();
    }
  }
};
</script>

<style scoped>
.scene-container {
  width: 100%;
  height: 300px;
  display: block;
}
</style>
